import cx from 'classnames';
import qs from 'query-string';
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import products from '../data/products';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/Button';
import { cleanTelStr, Input, TelInput } from '../components/FormControls';
import ServiceItem from '../components/ServiceItem';
import SuccessPage from '../components/SuccessPage';

import styles from './book.module.scss';

function getProduct(id) {
  return products.find(product => product.id === id);
}

export default class BookPage extends Component {
  state = {
    productId: 1,

    name: '',
    tel: '',
    email: '',
    isSaving: false,
    isSuccess: false,

    isTextVisible: false
  };

  componentDidMount() {
    this.setStateFromQuery();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location?.search !== this.props.location?.search) {
      this.setStateFromQuery();
    }
  }

  setStateFromQuery() {
    const query = qs.parse(this.props.location?.search || '');
    let { product } = query;
    if (product) {
      product = parseInt(product, 10);
      if (!Number.isNaN(product) && getProduct(product)) {
        this.setState({ productId: product });
      }
    }
  }

  submit = async () => {};

  onChangeForm = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  onClickPrev = () =>
    this.setState(state => ({ productId: state.productId > 1 ? state.productId - 1 : products.length }));

  onClickNext = () =>
    this.setState(state => ({ productId: state.productId < products.length ? state.productId + 1 : 1 }));

  shouldAllowSubmit() {
    const { name } = this.state;
    const tel = cleanTelStr(this.state.tel);
    return !!(name.trim() && tel && tel.trim().length === 8);
  }

  render() {
    const { productId, name, tel, email, isSaving, isSuccess, isTextVisible } = this.state;

    if (isSuccess) {
      return (
        <SuccessPage
          metaTitle="成功訂倉"
          title="您已成功提交預訂"
          content={<p>我們的服務主任將於24小時內來電聯絡您。</p>}
        />
      );
    }

    const product = getProduct(productId);

    return (
      <Layout location={this.props.location}>
        <SEO
          title="觀塘迷你倉價錢 | 小西灣 | 柴灣 | 石塘咀 | 紅磡 | 荔枝角 | 長沙灣租倉價格"
          description="Roomyr讓你儲物有方！提供本地儲存服務，有別一般傳統迷你倉，Roomyr針對香港用家要求，設不同類型及呎吋的迷你倉，迷你倉遍佈港九各地，包括長沙灣、觀塘、紅磡、石塘咀、小西灣、柴灣等。"
        />
        <section className={styles.lead}>
          <div className={styles.wrapper}>
            <div className={styles.product}>
              <ServiceItem {...product} />
              <div className={cx(styles.text, { [styles.visible]: isTextVisible })}>
                <div>
                  <div className={styles.heading}>簡介</div>
                  <p>{product.description}</p>
                </div>
                <div>
                  <div className={styles.heading}>熱門單位尺碼</div>
                  {product.popularSizes.map(size => (
                    <div key={size}>{size}</div>
                  ))}
                </div>
              </div>
              <div
                role="button"
                className={styles.toggleText}
                onClick={() => this.setState({ isTextVisible: !isTextVisible })}
              >
                {isTextVisible ? (
                  <FontAwesomeIcon fixedWidth icon={['fal', 'caret-down']} />
                ) : (
                  <FontAwesomeIcon fixedWidth icon={['fal', 'caret-up']} />
                )}
              </div>
            </div>

            <div className={cx(styles.arrow, styles.prev)} role="button" onClick={this.onClickPrev}>
              <FontAwesomeIcon fixedWidth icon={['fal', 'chevron-left']} />
            </div>

            <div className={cx(styles.arrow, styles.next)} role="button" onClick={this.onClickNext}>
              <FontAwesomeIcon fixedWidth icon={['fal', 'chevron-right']} />
            </div>
          </div>
        </section>
        <section className={styles.content}>
          <div className={styles.wrapper}>
            <h1>網上訂倉</h1>
            <p>
              Roomyr迷你倉遍佈港九各地，包括：小西灣迷你倉、柴灣迷你倉、石塘咀迷你倉、紅磡迷你倉、荔枝角迷你倉、觀塘迷你倉及長沙灣迷你倉。位置選擇多元化，用家可以根據自己住處、公司位置和迷你倉價錢去選擇最適合您的迷你倉。
            </p>
            <p>
              請輸入以下簡單資料，我們的服務主任將於24小時內來電了解您的需要，並為您推薦最合適的儲存方案。
              您亦可立即致電 2111 3113 聯絡我們。
            </p>
            <div className={styles.form}>
              <div className={styles.row}>
                <Input
                  name="name"
                  label="姓名"
                  placeholder=""
                  value={name}
                  maxLength={50}
                  disabled={isSaving}
                  onChange={this.onChangeForm}
                />
                <TelInput name="tel" label="電話" value={tel} disabled={isSaving} onChange={this.onChangeForm} />
              </div>
              <div className={styles.row}>
                <Input
                  name="email"
                  label="電郵"
                  placeholder=""
                  value={email}
                  maxLength={250}
                  disabled={isSaving}
                  onChange={this.onChangeForm}
                />
              </div>
              <div className={styles.button}>
                <Button tabIndex={0} disabled={!this.shouldAllowSubmit() || isSaving} onClick={this.submit}>
                  提交
                </Button>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
